var site = site || {};

(function() {

// @TODO: this needs to go before the prefilter... I think

// This shows a method for extending/customizing
// the site.api layer for a brand or locale

// site.api.overrides.products.url = function() {
//   return 'path/to/nothing';
// };

// site.api.overrides.foundation_finder = {
//   url: 'path/to/something'
// };

})();
